import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const AUTH_URL =
	process.env.NODE_ENV === "development"
		? process.env.REACT_APP_DEV_AUTH_URL
		: process.env.REACT_APP_PROD_AUTH_URL;

// const AUTH_URL = process.env.REACT_APP_PROD_AUTH_URL;

interface User {
	_id: string;
	name: string;
	email: string;
	mobile: Number;
	emergency_contact: Number;
	dob: string;
	address: string;
	password: string;
	verified: string;
	role: string;
	leave_data: [{ leave_type: string; leave_left: Number }] | undefined;
	leave_dates: [];
	createdAt: string;
	isProbationServed: boolean;
	assigned_HR: string;
	assigned_Manager: string;
}
export const userContext = createContext<{
	user: User | undefined;
	setUser: React.Dispatch<React.SetStateAction<User>>;
	token: string;
}>({
	user: {
		_id: "",
		name: "",
		email: "",
		mobile: 0,
		emergency_contact: 0,
		dob: "",
		address: "",
		password: "",
		verified: "",
		role: "",
		leave_data: undefined,
		leave_dates: [],
		createdAt: "",
		isProbationServed: false,
		assigned_HR: "",
		assigned_Manager: ""
	},
	setUser: (User) => User,
	token: ""
});

type props = {
	children: React.ReactNode;
};

const UserContext = ({ children }: props) => {
	const [token, setToken] = useState<string>("");
	const [user, setUser] = useState<User>({
		_id: "",
		name: "",
		email: "",
		mobile: 0,
		emergency_contact: 0,
		dob: "",
		address: "",
		password: "",
		verified: "",
		role: "",
		leave_data: undefined,
		leave_dates: [],
		createdAt: "",
		isProbationServed: false,
		assigned_HR: "",
		assigned_Manager: ""
	});

	async function fetchCurrrentUser() {
		try {
			const res = await axios.get(`${AUTH_URL}/currentEmployee`, {
				headers: {
					authorization: `Bearer ${token}`
				}
			});
			return res;
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (token !== "") {
			fetchCurrrentUser().then((res: any) => {
				if (res === undefined) {
					setUser({
						_id: "",
						name: "",
						email: "",
						mobile: 0,
						emergency_contact: 0,
						dob: "",
						address: "",
						password: "",
						verified: "",
						role: "",
						leave_data: undefined,
						leave_dates: [],
						createdAt: "",
						isProbationServed: false,
						assigned_HR: "",
						assigned_Manager: ""
					});
				}
				setUser(res?.data?.User);
			});
		}
	}, [token]);

	useEffect(() => {
		const bearer = window.localStorage.getItem("token");
		if (bearer) {
			setToken(bearer);
		}
	});

	return (
		<userContext.Provider value={{ user, setUser, token }}>
			{children}
		</userContext.Provider>
	);
};

export default UserContext;
